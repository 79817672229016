.profile-img-div {
  width: 6rem;
  height: 6rem;
  border: 1px solid black;
  border-radius: 50%;
  background: #2a3042;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-text {
  font-size: 3rem;
  color: white;
}
.profile-img-div-header {
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  border-radius: 50%;
  background: #2a3042;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-text-header {
  font-size: 2rem;
  color: white;
}

#page-header-user-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* for year and month select */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: left !important;
}
.divider {
  width: 97%;
  height: 1px;
  border-top: 1px solid gray;
  margin: 2rem;
}

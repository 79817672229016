/* .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.modal.show {
  opacity: 1;
}

.modal-dialog {
  animation: modal-show 0.3s;
}

@keyframes modal-show {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal.fade {
  opacity: 0;
}

.modal.fade .modal-dialog {
  animation: modal-fade 0.3s;
}

@keyframes modal-fade {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
} */

@media (max-width: 768px) {
  .custom-table-container {
    overflow-x: auto;
  }
}
.my-custom-table {
  width: 100%;
}

.my-custom-class {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.product-list-container {
  border: none;
  padding: 1rem;
  margin: 1rem 0;
}
/* .reduced-width {
  width: 50%; /* Set a reduced width for the container */
/* } */
#productCategory {
  width: 218%;
  padding: 8px;
  /* margin-top: 3px; */
  box-sizing: border-box;
}
.product-info {
  display: flex;
  align-items: center;
}

.product-info span {
  flex-grow: 1;
}

.remove-button {
  background: none;
  border: none;
  cursor: pointer;
}

.product-list-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.product-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.product-list li {
  margin: 5px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
}

.product-list li:hover {
  background-color: #ddd;
}

/* @media (min-width: 768px) {
  .product-list.laptop-view li {
    flex-basis: 100%;
  }
} */

.boxes-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-top: 30px;
}
.custom-input {
  width: 50px;
}

.box {
  border: 2px solid #eee;
  border-radius: 10px;
  /* padding: 20px; */
  background-color: #fff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .boxes-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.box:hover {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.box1 {
  background-color: #bde3fc;
}

.box2 {
  background-color: #fff1cc;
}

.date-picker-textbox {
  width: 200px;
  height: 30px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.time-picker-container {
  display: inline-block;
  position: relative;
}

.time-picker-input {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.time-field {
  margin-top: 10px;
}

@media (max-width: 600px) {
  #productCategory {
    width: 143%;
  }
}
